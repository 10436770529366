import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getCompanies } from "../../../actions/Companies/Companies";
import FormContainer from "../../../components/FormContainer/FormContainer";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import { Header } from "../../../containers/Layout/Header";
import { states } from "../../../library/Utilities";
import AddSiteForm from "./AddSiteForm";

class AddSite extends Component {
  constructor() {
    super();

    this.state = {
      states: states,
    };
  }

  setRef = (ref) => {
    this.setState({
      editorRef: ref,
    });
  };

  componentDidMount() {
    this.props.GetCompanies("asc", 100, 0, "id");
  }

  render() {
    const { companies } = this.props;
    const { states } = this.state;

    const links = [
      {
        title: "All Sites",
        link: "/#/sites",
      },
      {
        title: "Add Site",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header no-flex mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>Add Site
          </PageTitle>
        </div>
        <div className="mb-4 content flex-auto mx-4">
          <FormContainer
            {...this.props}
            setRef={this.setRef}
            entity="sites"
            action="add"
            redirect={`/sites`}
            Form={AddSiteForm}
            initialValues={{
              company_id: this.props.match.params.id,
            }}
            handleValues={(values) => {
              return {
                ...values,
                notes: this.state.editorRef.getContent(),
              };
            }}
            companies={companies}
            states={states}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companyService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanies: (direction, limit, offset, sort) => dispatch(getCompanies(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSite);
