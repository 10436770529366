import ASCButton from "@alliancesafetycouncil/asc-button";
import Card from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import TextInput from "../../../components/FormContainer/TextInput";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { required } from "../../../library/Validation";

let EditRequirementForm = ({ initialValues, setRef, handleSubmit, pristine, busy, history }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Card type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="reqName"
                placeholder=""
                label="Requirement Name"
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <TextEditor setRef={setRef} initialValue={initialValues.notes} />
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || busy}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

EditRequirementForm = reduxForm({
  form: "addRequirement",
  enableReinitialize: true,
})(EditRequirementForm);

export default EditRequirementForm;
