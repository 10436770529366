import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequirement } from "../../../actions/Sites/Sites";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import TabDisplay from "../../../components/TabDisplay/TabDisplay";
import { Header } from "../../../containers/Layout/Header";
import DetailsTab from "./DetailsTab";
import DocumentTab from "./DocumentTab";

class RequirementDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emergencyModal: false,
      config: [
        {
          name: "Details",
          path: "/details",
          default: false,
          component: DetailsTab,
          default: true,
          exact: true,
        },
        {
          name: "Documents",
          path: "/documents",
          component: DocumentTab,
          exact: true,
        },
      ],
    };
  }

  componentWillMount() {
    const { id } = this.props.match.params;

    this.props.GetRequirement(id);
  }

  render() {
    const { details, requirementDetails, auth } = this.props;
    const { id } = this.props.match.params;

    const links = [
      {
        title: details.company.name,
        link: `/#/companies/company-details/${details.company.id}/sites`,
      },
      {
        title: details.name,
        link: `/#/sites/site-details/${details.id}/sites-requirements`,
      },
      {
        title: requirementDetails.name,
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>
            {requirementDetails.name}
          </PageTitle>
          {auth && <a href={`/#/requirements/edit-requirement/${id}`}>Edit</a>}
        </div>
        <div className="mb-4 row content flex-auto mx-4">
          <TabDisplay
            {...this.props}
            tabs={this.state.config}
            edit={{
              path: `/#/requirements/edit-requirement/${id}`,
              label: "Edit Requirement",
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.details,
    requirementDetails: state.siteService.requirementDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetRequirement: (id) => dispatch(getRequirement(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequirementDetails);
